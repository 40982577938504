// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$logo-name: 'logo';
$logo-x: 0px;
$logo-y: 0px;
$logo-offset-x: 0px;
$logo-offset-y: 0px;
$logo-width: 340px;
$logo-height: 92px;
$logo-total-width: 340px;
$logo-total-height: 92px;
$logo-image: '../img/sprite.png';
$logo: (0px, 0px, 0px, 0px, 340px, 92px, 340px, 92px, '../img/sprite.png', 'logo', );
$logo-2x-name: 'logo@2x';
$logo-2x-x: 0px;
$logo-2x-y: 0px;
$logo-2x-offset-x: 0px;
$logo-2x-offset-y: 0px;
$logo-2x-width: 680px;
$logo-2x-height: 184px;
$logo-2x-total-width: 680px;
$logo-2x-total-height: 184px;
$logo-2x-image: '../img/sprite@2x.png';
$logo-2x: (0px, 0px, 0px, 0px, 680px, 184px, 680px, 184px, '../img/sprite@2x.png', 'logo@2x', );
$spritesheet-width: 340px;
$spritesheet-height: 92px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($logo, );
$spritesheet: (340px, 92px, '../img/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 680px;
$retina-spritesheet-height: 184px;
$retina-spritesheet-image: '../img/sprite@2x.png';
$retina-spritesheet-sprites: ($logo-2x, );
$retina-spritesheet: (680px, 184px, '../img/sprite@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$logo-group-name: 'logo';
$logo-group: ('logo', $logo, $logo-2x, );
$retina-groups: ($logo-group, );


