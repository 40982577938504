@media (max-width: 767px) {
	.mb-slidebar {
		.search {
			margin-right: 0px;
		}
	}
	.header {
		.auth-user {
			width: auto;
			margin-top: 15px;
			margin-right: 20px;
		}
		.auth-user__dropdown {
			width: 140px;
			left: -60px;
		}
	}
	.header .shopcart-block, .header .login-bt {
		margin-top: 16px;
	}
	.login-bt {
		.login-bt__val {
			display: none;
		}
	}
	.logo {
		width: 153px;
		height: 39px;
		margin-left: 10px;
	}
	.book-price {
		font-size: 40px;
		padding-top: 6px;
		.icon-ruble {
			width: 25px;
    	height: 29px;
		}
	}
	.book-preview__photo {
		margin: 0 auto 20px;
	}
	.book-preview {
		text-align: center;
	}
	.company-affiliate__info {
		float: none;
		width: 100%;
	}
	.company-affiliate__map {
		width: 100%;
	}
	.international-contacts .contact-item {
    float: none;
    width: 100%;
	}
	.contact-item--col {
		width: 100%;
		float: none;
	}
	.book-cover {
		width: 100%;
		height: auto;
	}
	.book-name {
		font-size: 30px;
		line-height: 35px;
	}
	.office-menu__link {
		padding: 0 10px;
	}
	.accordion-orders__caption {
		height: auto;
		line-height: normal;
    padding: 10px 25px;
	}
	.accordion-orders__order-number {
		display: block;
		margin-bottom: 5px;
	}
	.accordion-orders__order-date {
		margin: 0;
	}
	.accordion-orders__container {
		padding: 15px;
	}
	.order-history__prod-name, .order-history__prod-price, .order-history__prod-total {
		height: auto;
		line-height: normal;
	}
	.order-history__prod-name {
		margin-right: 0;
		width: 180px;
		margin-bottom: 20px;
	}
	.order-history__delivery {
		margin-top: 20px;
		width: 100%;
	}
	.order-form .form-subm {
		text-align: center;
	}
	.about-partner__logo {
		width: 100%;
		margin: 0 0 40px 0;
		float: none;
	}
	.about-partner {
		margin: 0;
		padding: 30px 0;
	}
	.phone--footer {
		float: none;
		text-align: center;
		margin-right: 0;
		margin-bottom: 20px;
	}
	.address {
		text-align: center;
		margin-bottom: 20px;
	}
	.devepoler {
		float: none!important;
		margin: 0 auto!important;
		width: 80px!important;
	}
	.header > .container > .row,
	.header.container > .row {
		display: flex;
	}
	.header div[class^="col-"] {
		padding: 0
	}
	.mobile-menu-toggle {
		margin-top: 15px;
	}
	.shopcart-block {
		
		position: relative;
		display: block;
		.shopcart-block__val {
			position: absolute;
			background: #6f9aac;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			top: -10px;
			right: -5px;
			text-align: center;
			color: #fff;
			font-size: 12px;
			line-height: 18px;
		}
	}
	.fancybox-slide form {
		width: auto;
		padding: 50px 20px;
	}
	.error-text {
		position: static;
	}
}