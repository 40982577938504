@import "libs/sprite_main";
@import "sprite-mixins";
html {
	position: relative;
  min-height: 100%;
}
body {
	font-family: 'Ubuntu', sans-serif;
	color: #454444;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	background: #FFF;
	margin-bottom: 216px;
}
.has-bg {
	background: #e5edf0;
}
h1, h2, h3, h4 {
	font-weight: bold;
}
h1 {
	font-size: 60px;
	line-height: 70px;
	&.article-title {
		font-size: 48px;
		line-height: 50px;
	}
}
h2 {
	font-size: 48px;
	line-height: 70px;
}
h3 {
	font-size: 36px;
	line-height: 42px;
}
h4 {
	font-size: 18px;
	line-height: 26px;
}
.logo {
	@include retina-sprite($logo-group);
	display: block;
}
.top-line {
	height: 8px;
	background-color: #edb14e;
}
.header {
	padding-top: 17px;
	background: #ffffff;
	position: relative;
	&--index {
		&:before {
			display: none;
		}
	}
	&:before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 1px;
		width: 100%;
		border-bottom: 1px solid #e5edf0;
	}
}
.search {
	margin-top: 7px;
}
.search-form {
	position: relative;
}
.search__field {
	height: 40px;
	background-color: white;
	border: 1px solid #e3e3e3;
	padding: 0 55px 0 20px;
	width: 100%;
}
.search__submit {
	text-align: center;
	padding: 0;
	font-size: 0;
	height: 40px;
	width: 40px;
	position: absolute;
	top: 0;
	right: 0;
	background: none;
	border: 0;
	line-height: 0;
}
.search__submit-icon {
	width: 16px;
	height: 16px;
	fill: #757575;
}

.phone {
	color: #454444;
	font-size: 18px;
	b {
		font-size: 18px;
		font-weight: 400;
	}
	&--header {
		float: right;
		text-align: right;
		margin-top: 5px;
	}
	&--footer {
		float: left;
		color: #FFF;
		margin-right: 46px;
		font-size: 20px;
		b {
			font-size: 20px;
		}
	}
	a {
		color: #edb14e;
		@media (hover:hover) {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.address {
	overflow: hidden;
	line-height: 20px;
	span {
		border-bottom: 1px dotted #6694a6;
		display: inline-block;
	}
}
.callback-show {
	background: none;
	padding: 0;
	display: inline-block;
	line-height: 19px;
	font-size: 14px;
	color: #004c6b;
	border: 0;
	border-bottom: 1px dotted #b2b2b2;
	@media (hover:hover) {
		&:hover, &:focus {
			color: #edb14e;
			border-bottom-color: #FFF;
		}
	}
}

.shopcart-block, .login-bt {
	float: right;
	color: #004c6b;
	font-size: 14px;
	margin: 15px 10px 0 0;
	@media (hover:hover) {
		&:hover {
			color: #edb14e;
			.shopcart-block__icon, .login-bt__icon {
				fill: #edb14e;
			}
		}
	}
}
.shopcart-block__icon, .login-bt__icon {
	width: 24px;
	height: 20px;
	fill: #6f9aac;
	margin-right: 6px;
	vertical-align: -2px;
}
.shopcart-block__val, .login-bt__val {

}

.login-bt__icon {
	width: 24px;
	height: 18px;
	vertical-align: -3px;
}

.main-menu {
	display: flex;
	list-style: none;
	padding: 0;
	justify-content: space-between;
	margin: 10px 0 0 0;
}
.main-menu__item {
	padding: 0 10px;
}
.main-menu__item--has-bropdown {
	position: relative;
	&:hover {
		background-color: #e5edf0;
		.dropdown-menu {
			display: block;
		}
	}
}
.main-menu__link {
	font-size: 14px;
	text-transform: uppercase;
	color: #2d2d2d;
	height: 50px;
	line-height: 50px;
	position: relative;
	display: block;
	@media (hover:hover) {
		&:hover {
			color: #004c6b;
			&:after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 3px;
				background-color: #edb14e;
			}
		}
	}
}

.main-menu__link--has-bropdown {
	@media (hover:hover) {
		&:hover {
			color: #2d2d2d;
			&:after {
				display: none;
			}
		}
	}
}

.dropdown-menu {
	z-index: 60;
	display: none;
	position: absolute;
	margin: 0;
	padding: 20px;
	list-style: none;
	width: 317px;
	background-color: #e5edf0;
	left: 0;
	top: 50px;
}
.dropdown-menu__item {
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
}
.dropdown-menu__link {
	white-space: nowrap;
	color: #2d2d2d;
	font-size: 16px;
	@media (hover:hover) {
		&:hover {
			color: #d79d4e;
		}
	}
}

.footer {
	position: absolute;
	width: 100%;
	bottom: 0;
	height: 216px;
	background-color: #004c6b;
	color: #FFF;
	font-size: 14px;
}

.footer-menu {
	margin: 45px 0 0 0;
	padding: 0;
	display: flex;
	justify-content: space-between;
	list-style: none;
}
.footer-menu__item {
	
}
.footer-menu__link {
	color: #FFF;
	font-size: 14px;
	text-transform: uppercase;
	@media (hover:hover) {
		&:hover {
			color: #edb14e;
		}
	}
}
.skilet-button {
	width: 230px;
	height: 48px;
	border: 1px solid #dce4e7;
	padding: 0;
	font-size: 14px;
	text-transform: uppercase;
	line-height: 48px;
	text-align: center;
	color: #004c6b;
	display: block;
	@media (hover:hover) {
		&:hover {
			border-color: #004c6b;
		}
	}
}
.skilet-button--dark {
	border: 1px solid #a0bcc8;
}
.solid-button {
	font-weight: normal;
	width: 269px;
	height: 48px;
	background: #edb14e;
	border: 0;
	color: #004c6b;
	letter-spacing: 0.6px;
	font-size: 14px;
	text-transform: uppercase;
	@media (hover:hover) {
		&:hover, &:focus {
			background: #d79d4e;
		}
	}
	&--yellow {
		background:#004c6b;
		color: #FFF;
		@media (hover:hover) {
			&:hover {
				background:#265e95;
			}
		}
	}
	&--to-cart {
		width: 179px;
	}
}

.show-subscribe--footer {
	margin-top: 30px;
	float: right;
}

.footer-hr {
	margin: 30px 0;
	border-top-color: #00354b;
}

.devepoler {
	float: right!important;
	width: auto!important;
}

.book-preview {
	margin-bottom: 35px;
	@media (hover:hover) {
		&:hover {
			.book-preview__photo img {
				width: 234px;
				height: auto;
			}
		}
	}
}
.book-preview__photo {
	display: block;
	width: 212px;
	height: 282px;
	position: relative;
	margin-bottom: 20px;
	img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: auto;
		box-shadow: 6px 6px 6px 0 rgba(0,0,0,.2);
		transition: all 0.4s ease-out;
	}
}
.book-preview__name {
	color: #004c6b;
	line-height: 20px;
	@media (hover:hover) {
		&:hover {
			color: #edb14e;
		}
	}
}
.book-preview__price {
	font-size: 20px;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 15px;
	color: #2e2d2c;
}
.icon-ruble {
	width: 13px;
	height: 14px;
	fill: #2e2d2c;
	&--small {
		width: 10px;
		height: 12px;
		fill:#454444;
	}
	&--middle {
		width: 16px;
		height: 14px;
		fill:#454444;
	}
	&--large {
		width: 34px;
		height: 33px;
		fill:#454444;
	}
}
.add-to-cart {
	color: #004c6b;
	font-size: 16px;
	padding: 0;
	background: none;
	border: 0;
	&:hover, &--active {
		color: #edb14e;
		.add-to-cart__icon {
			fill: #edb14e;
		}
	}
}
.add-to-cart__icon {
	width: 24px;
	height: 20px;
	fill: #6f9aac;
	vertical-align: -3px;
	margin-right: 7px;
}

.categories {
	font-size: 0;
	line-height: 0;
	margin-bottom: 40px;
}
.categories__item {
	display: inline-block;
	color: #3c3b3a;
	font-size: 16px;
	padding: 0 18px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	&:last-child {
		margin-right: 0;
	}
	.icon-star {
		width: 21px;
		height: 20px;
		fill: #6f9aac;
		margin-right: 8px;
		vertical-align: -3px;
	}
	&--active {
		background-color: #e5edf0;
		color: #6f9aac;
		font-weight: bold;
	}
}
// .categories__item--bestsellers {
// 	width: 175px;
// 	height: 40px;
// 	background-color: #e5edf0;
// 	color: #6f9aac;
// 	font-weight: bold;
// 	text-align: center;
// 	line-height: 40px;
	
// }

.load-more {
	margin: 0 auto 30px;
	display: block;
	width: 230px;
	height: 48px;
	border: 1px solid #dce4e7;
	background: none;
	padding: 0;
	color: #004c6b;
	text-transform: uppercase;
	font-size: 14px;
	svg {
		margin-right: 16px;
		width: 25px;
		height: 23px;
		fill: #c9c9c9;
		vertical-align: middle;
		transition: all 0.2s ease;
	}
	@media (hover:hover) {
		&:hover {
			border-color: #004c6b;
			svg {
				fill: #004c6b;
				transform: rotate(180deg);
			}
		}
	}
}

.pager {
	text-align: center;
	font-size: 0;
	padding: 48px 0 100px 0;
	border-top: 1px solid #e5edf0;
	margin-top: 48px;
}
.pager__link {
	display: inline-block;
	width: 24px;
	height: 24px;
	font-size: 16px;
	line-height: 24px;
	color: #2e2d2c;
	margin: 0 10px;
	&:hover, &--active {
		color: #FFF;
		background-color: #edb14e;
	}
}
.pager__link--spacer {
	@media (hover:hover) {
		&:hover {
			background: none;
			color: #2e2d2c;
		}
	}
}


.subscribe-banner {
	height: 300px;
	background: #edb14e;
	margin: 70px 0 70px 0;
	position: relative;
	&:after {
		content: "";
		position: absolute;
		width: 432px;
		height: 309px;
		background: url(../img/package.jpg);
		right: 128px;
		top: -46px;
	}
}

.subscribe-banner__text {
	font-size: 32px;
	font-weight: 700;
	line-height: 36px;
	color: #FFF;
	padding: 50px 0 0 80px;
}

.main-slider {
	position: relative;
}
.slick-nav-button {
	width: 77px;
	height: 72px;
	background:#e5edf0;
	border: 0;
	padding: 0;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 20;
	outline: none;
	@media (hover:hover) {
		&:hover {
			background:#dae2e6;
		}
	}
}
.slick-prev {
	right: 77px;
	text-align: right;
}
.slick-next {
	text-align: left;
}
.slick-prev__icon, .slick-next__icon {
	width: 12px;
	height: 18px;
	fill: #757575;
	vertical-align: middle;
}
.slick-prev__icon {
	transform: rotate(-180deg);
	margin-right: 22px;
}
.slick-next__icon {
	margin-left: 22px;
}

.slick-nav-button--outer {
	width: 48px;
	height: 72px;
	text-align: center;
	bottom: auto;
	top: 100px;
	&.slick-prev {
		right: auto;
		left: -48px;
	}
	&.slick-next {
		right: -48px;
	}
	.slick-prev__icon, .slick-next__icon {
		margin: 0;
	}
}

.book-slider {
	.book-preview {
		width: 221px;
		margin: 0 auto;
	}
}
.to-catalog {
	margin: 50px auto 70px;
}

.line {
	border-color: #e5edf0;
	margin: 70px 0;
}

.advantages {
	border: 8px solid #e5edf0;
	padding: 40px 33px;
	margin-top: 80px;
}
.advantages__item {
	overflow: hidden;
	margin-bottom: 40px;
	&:last-child {
		margin-bottom: 0;
	}
	&--inside {
		margin: 35px 0;
		padding: 35px 50px 35px 90px;
		border-top: 1px #e5edf0 solid;
		border-bottom: 1px #e5edf0 solid;
	}
}
.advantages__icon {
	width: 42px;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
}
.advantages__text {
	font-weight: 500;
	display: table-cell;
	vertical-align: middle;
	padding-left: 19px;
}

.icon-adv-qualification {
	width: 37px;
	height: 40px;
}
.icon-adv-high-quality {
	width: 42px;
height: 45px;
}
.icon-adv-no-ad {
	width: 42px;
height: 35px;
}
.icon-adv-selection-book {
	width: 32px;
height: 44px;
}
.icon-adv-library {
	width: 37px;
height: 54px;
}


.news-and-articles {
	background-color: #e5edf0;
	padding-bottom: 100px;
	margin-top: 100px;
}

.article-preview {
	background: #FFF;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	margin-bottom: 32px;
}
.article-preview__img {
	display: block;
	width: 100%;
	height: auto;
}
.article-preview__info {
	overflow: hidden;	
}
.article-preview__name {
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	color: #3c3b3a;
	display: block;
	margin: 30px;
}
.article-preview__source {
	color: #9c9997;
	line-height: 22px;
	margin: 30px;
}
.article-preview__autor {
	color: #3c3b3a;
	margin: 37px 30px 30px;
}
.article-preview__category {
	border-top: 1px #e5edf0 solid;
	padding: 20px 30px;
	line-height: 18px;
}
.article-preview__category-link {
	color: #004c6b;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
}

.to-articles {
	width: 298px;
	margin: 48px auto 0;
}


.latest-news {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid #d0d7da;
	&:last-child {
		border-bottom: 0;
		margin: 0;
		padding: 0;
	}
}
.latest-news__date {
	color: #9c9997;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 10px;
}
.latest-news__title {
	color: #3c3b3a;
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
}
.latest-news__text {
	margin-top: 10px;
}
.partners {
	padding-bottom: 100px;
	h2 {
		margin-bottom: 70px;
	}
}
.partner-item {
	height: 111px;
	line-height: 111px;
	margin-bottom: 40px;
	// a {
	// 	filter: grayscale(100%);
	// 	opacity: .7;
	// 	img {
	// 		vertical-align: middle;
	// 	}
	// 	&:hover {
	// 		filter: grayscale(0%);
	// 		opacity: 1;
	// 	}
	// }
	&--mini {
		text-align: center;
		height: 105px;
		line-height: 105px;
		float: left;
		width: 50%;
		img {
			max-width: 100px;
			height: auto;
		}
	}
}
.partnesr-mini {
	padding: 35px 37px;
	background: #e5edf0;
	h3 {
		margin-top: 0;
	}
}
.to-partners {
	width: 298px;
	margin: 40px auto 0;
	&--mini {
		width: 100%;
	}
}

.fancybox-close-small:after {
	font-size: 26px;
	color: #757575;
}
.fancybox-slide > * {
	padding: 0;
}
.fancybox-slide form {
	padding: 70px 120px 70px 120px;
	width: 456px;
	box-sizing: content-box;
}
.form-title {
	font-family: Ubuntu;
	font-size: 36px;
	font-weight: 700;
	margin-bottom: 30px;
}
.form-note {
	color: #757575;
	margin-bottom: 20px;
}
.fl-row {
	margin-bottom: 20px;
	position: relative;
	label {
		display: block;
		font-weight: 700;
		margin-bottom: 3px;
	}
	input {
		height: 40px;
		background-color: white;
		border: 1px solid #e3e3e3;
		padding: 0 15px;
		width: 100%;
		&:focus {
			outline: 0;
			background:#e5edf0;
		}
	}
	textarea {
		background-color: white;
		border: 1px solid #e3e3e3;
		padding: 10px 15px;
		width: 100%;
		resize: none;
		height: 127px;
		&:focus {
			outline: 0;
			background:#e5edf0;
		}
	}
}
.fl-row__error {
	label {
		color: red;
	}
	input {
		border-color: red;
	}
}
.form-subm {
	text-align: center;
	margin-top: 40px;
	input {
		width: 265px;
		height: 48px;
		// background-color: #edb14e;
		// border: 0;
		// color: #004c6b;
		// font-size: 14px;
		// font-weight: 400;
		// text-transform: uppercase;
		// padding: 0;
		// text-align: center;
		// &:hover {
		// 	background:#d79d4e;
		// 	color: #FFF;
		// }
	}
}
.popup-bottom-link {
	width: 100%;
	padding: 0;
	height: 80px;
	background-color: #e5edf0;
	border: 0;
	color: #004c6b;
}
.error-text {
	color: red;
	font-size: 14px;
	position: absolute;
	right: 0;
	top: 0;
}

.prod-count {
	overflow: hidden;
	margin-bottom: 45px;
	&__button {
		width: 40px;
		height: 40px;
		padding: 0;
		border: 0;
		background:#e5edf0;
		float: left;
		color: #757575;
		position: relative;
		outline: 0;
		@media (hover:hover) {
			&:hover {
				background:#dae2e6;
			}
		}
		&--plus {
			&:before {
				content: "";
				width: 2px;
				height: 14px;
				background-color: #757575;
				position: absolute;
				left: 50%;
				top: 50%;
				margin: -7px 0 0 -1px
			}
			&:after {
				content: "";
				width: 14px;
				height: 2px;
				background-color: #757575;
				position: absolute;
				left: 50%;
				top: 50%;
				margin: -1px 0 0 -7px
			}
		}
		&--minus {
			&:after {
				content: "";
				width: 14px;
				height: 2px;
				background-color: #757575;
				position: absolute;
				left: 50%;
				top: 50%;
				margin: -1px 0 0 -7px
			}
		}
	}
	&__input {
		width: 46px;
		height: 40px;
		float: left;
		border: 0;
		border-top: 1px #e5edf0 solid;
		border-bottom: 1px #e5edf0 solid;
		text-align: center;
	}
}
.book-name {
	font-size: 48px;
	line-height: 56px;
	margin-top: 0;
}
.book-info {
	margin: 0 0 30px 0;
}
.book-info__item {
	
}
.book-price {
	font-size: 48px;
	font-weight: 700;
	float: left;
	margin-right: 26px;
	padding-top: 3px;
	.icon-ruble {
		width: 30px;
		height: 32px;
		fill: #454444;
		margin-left: 10px;
	}
}
.book-cover {
	box-shadow: 6px 6px 6px 0 rgba(0,0,0,.2);
	margin-top: 10px;
}
.book-description {
	height: 340px;
	overflow: hidden;
	h3 {
		margin-top: 0;
	}
}
.breadcrombs {
	margin: 30px 0 20px 0;
}
.back-to-catalog {
	color: #9c9997;
	.icon-arrow {
		fill: #9c9997;
		width: 7px;
		height: 12px;
		margin-right: 13px;
		transform: rotate(180deg);
	}
}
.show-description {
	padding: 0;
	border: 0;
	border-bottom: 1px dotted #b2b2b2;
	color: #004c6b;
	outline: 0;
	background: none;
}
.content-col {
	margin-bottom: 80px;
}
.sidebar {
	margin-top: 55px;
}

.article-themes {

}
.article-themes__item {
	margin-bottom: 11px;
}
.article-themes__link {
	color: #3c3b3a;
	padding: 0 20px;
	height: 40px;
	line-height: 40px;
	display: inline-block;
	&:hover, &--active {
		background: #FFF;
	}
}
.article-themes__count {
	color: #9c9997;
}

.contact-item {
	color: #3c3b3a;
	margin: 30px 0;
}
.contanc-item__name {
	font-weight: 700;
	margin-bottom: 7px;
	padding-bottom: 8px;
	position: relative;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 32px;
		height: 3px;
		background-color: #edb14e;
	}
}
.contact-tem__addr {
	margin-bottom: 5px;
}
.contact-item__email {
	overflow: hidden;
	margin-bottom: 4px;
	a {
		color: #004c6b;
	}
}
.contact-item__mail-icon {
	width: 16px;
	height: 12px;
	fill: #004c6b;
	float: left;
	margin: 6px 10px 0 0;
}
.contanct-item__phone {
	overflow: hidden;
	span {
		overflow: hidden;
		display: inline-block;
		width: 160px;
	}
}
.contact-item__phone-icon {
	width: 18px;
	height: 18px;
	fill: #004c6b;
	float: left;
	margin: 3px 10px 0 0;
}
.contact-item--col {
	width: 212px;
	margin: 30px 15px;
	float: left;
}
.international-contacts {
	background: #e5edf0;
	padding: 0 30px;
	overflow: hidden;
	.contact-item {
		margin-bottom: 35px;
	}
}

.company-affiliate {
	overflow: hidden;
}
.company-affiliate__info {
	float: left;
	width: 366px;
	background-color: #e5edf0;
	height: 620px;
	padding: 25px 20px;
}
.company-affiliate__map {
	overflow: hidden;
	height: 620px;
}

.company-affiliate__city {
	font-weight: 700;
	font-size: 18px;
}
.company-affiliate__addr {
	margin: 10px 0;
}
.company-affiliate__phone {
	font-weight: 700;
	margin: 10px 0;
}
.company-affiliate__email {
	margin: 10px 0;
}
.company-affiliate__uraddr {
	margin: 10px 0 80px 0;
	b {
		display: block;
	}
}

.person {
	overflow: hidden;
	margin-bottom: 15px;
}
.person__photo {
	float: left;
	margin-right: 20px;
}
.person__info {
	overflow: hidden;
	width: 110px;
}
.person__name {
	margin: 10px 0 0 0;
	font-weight: 700;
}

.article-contents {
	background: #e5edf0;
	overflow: hidden;
	margin: 40px 0 50px 0;
}
.article-content__item {
	margin:  15px 0;
}
.article-content__link {
	display: block;
	padding: 12px 33px;
	color: #3c3b3a;
	position: relative;
	&:hover, &--active {
		color: #edb14e;
	}
	&--active {
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 3px;
			height: 48px;
			background-color: #edb14e;
		}
	}
}
.p404__line {
	width: 100%;
	height: 8px;
	background-color: #edb14e;
}
.p404__text {
	text-align: center;
	font-size: 20px;
	b {
		font-size: 36px;
		line-height: 42px;
		font-weight: 700;
		display: block;
	}
	.skilet-button {
		margin: 0 auto;
	}
}
.p404__text-large {
	font-size: 251px;
	line-height: 292px;
	font-weight: 700;
	
}
.logo--404 {
	margin: 35px auto 50px;
}
.contacts-map {
	width: 100%;
	height: 100%;
}
ymaps[class*="ground-pane"] {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
    -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}
.article-page {
	h2 {
		font-size: 36px;
		line-height: 40px;
		a {color: #454444;}
	}
	h3 {
		font-size: 23px;
		line-height: 30px;
		a {color: #454444;}
	}
	h4 {
		font-size: 18px;
		line-height: 24px;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 16px 0 16px 20px;
		& > li {
			position: relative;
			padding-left: 15px;
			&::before {
				content: "";
				position: absolute;
				width: 6px;
				height: 6px;
				background-color: #edb14e;
				border-radius: 50%;
				top: 9px;
				left: 0;
			}
		}
	}
	ol {
		list-style: none;
		padding: 0;
		margin: 16px 0 16px 20px;
		& > li {
			counter-increment: step-counter;
			&:before {
				content: counter(step-counter) ". ";
				font-weight: bold;
			}
		}
	}
}

.about-partner {
	padding: 35px 40px 35px 0;
	border-bottom: 1px #e5edf0 solid;
	overflow: hidden;
}
.about-partner__logo {
	width: 212px;
	float: left;
	text-align: center;
	padding-top: 6px;
	margin-right: 10px;
}
.about-partner__info {
	overflow: hidden;
}
.about-partner__name {
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 10px;
}
.about-partner__link {
	color: #004c6b;
}
.about-partner__text {
	margin-top: 15px;
}

.office-menu {
	border-bottom: 1px solid #e5edf0;
	padding-bottom: 18px;
	margin: 20px 0 15px 0;
}
.office-menu__link {
	display: inline-block;
	height: 40px;
	line-height: 40px;
	padding: 0 20px;
	color: #3c3b3a;
	&:hover, &--active {
		background: #e5edf0;
	}
}
.accordion__item-container {
	display: none;
}


.accordion-orders {

}
.accordion-orders__caption {
	height: 48px;
	line-height: 48px;
	background-color: #e5edf0;
	padding: 0 25px;
	margin-bottom: 2px;
	position: relative;
	&.accordion__item-caption--active {
		margin-bottom: 0;
	}
}
.accordion__item-caption {
	cursor: pointer;
	&--active {
		.accordion__caption-icon {
			transform: rotate(-90deg);		
		}
	}
}
.accordion__caption-icon {
	position: absolute;
	width: 9px;
	height: 16px;
	fill: #757575;
	transform: rotate(90deg);
	right: 22px;
	top: 50%;
	margin-top: -7px;
}
.accordion-orders__container {
	border: 1px #e5edf0 solid;
	padding: 25px 45px;
	margin-bottom: 25px;
}


.accordion-orders__order-number {
	font-weight: 700;
}
.accordion-orders__order-date {
	margin: 0 10px;
}
.accordion-orders__order-status {
	&:before {
		content: "";
		display: inline-block;
		width: 8px;
		height: 8px;
		background: #454444;
		border-radius: 50%;
		margin-right: 8px;
	}
	&--finish {
		color: #1fa568;
		&:before {
			background: #1fa568;
		}
	}
	&--canceled {
		color: #ff0000;
		&:before {
			background: #ff0000;
		}
	}
}


.order-history__item {
	overflow: hidden;
	margin-bottom: 30px;
}
.order-history__prod-img {
	float: left;
	margin-right: 18px;
	img {
		width: 88px;
		height: auto;
	}
}
.order-history__prod-name {
	width: 275px;
	float: left;
	margin-right: 32px;
	height: 117px;
	line-height: 117px;
	a {
		display: inline-block;
		line-height: normal;
		vertical-align: middle;
		font-size: 14px;
	}
}
.order-history__prod-price {
	width: 100px;
	float: left;
	height: 117px;
	line-height: 117px;
	font-size: 16px;
	span {
		display: inline-block;
		line-height: normal;
		vertical-align: middle;
	}
}
.order-history__prod-total {
	width: 88px;
	float: right;
	height: 117px;
	line-height: 117px;
	text-align: right;
	.book-preview__price {
		display: inline-block;
		line-height: normal;
		vertical-align: middle;
		margin: 0;
	}
}
.order-history__info {
	overflow: hidden;
	border-top: 1px solid #e5edf0;
	padding-top: 20px;
}
.order-history__total {
	float: right;
	width: 88px;
}
.order-history__total {
	width: 170px;
	text-align: right;
	span {
		font-size: 16px;
		margin-right: 15px;
	}
	.book-preview__price {
		display: inline-block;
		margin: 0;
	}
}

.order-history__delivery {
	float: left;
	width: 367px;
	font-size: 14px;
}
.order-history__delivery-item {
	margin-bottom: 1px;
}
.order-history__delivery-label {
	font-weight: 700;
	width: 128px;
	float: left;
}
.order-history__delivery-val {
	overflow: hidden;
}


.cart-page {
	padding-bottom: 80px;
	overflow: hidden;
}
.cart-item {
	overflow: hidden;
	padding: 60px 0;
	border-bottom: 1px solid #e5edf0;
}
.cart-item__prod-photo {
	width: 122px;
	margin-right: 30px;
	float: left;
	img {
		width: 122px;
		height: auto;
	}
}
.cart-item__prod-info {
	width: 424px;
	float: left;
	margin-right: 30px;
	.book-info {
		margin-top: 15px;
		font-size: 14px;
		line-height: 18px;
		.book-info__item {
			margin-bottom: 5px;
		}
	}
}
.cart-item__prod-name {
	font-size: 18px;
}
.cart-item__prod-price {
	width: 122px;
	float: left;
	margin-top: 70px;
	font-size: 20px;
	font-weight: 700;
}
.cart-item__prod-count {
	width: 129px;
	margin-right: 30px;
	margin-top: 60px;
	float: left;
	.prod-count {
		margin: 0;
	}
}
.cart-item__prod-total {
	float: left;
	font-size: 48px;
	font-weight: 700;
	margin-top: 63px;
}
.cart-item__remove {
	float: right;
	width: 22px;
	height: 22px;
	margin-top: 70px;
	background:none;
	position: relative;
	border: 0;
	outline: none;
	&::before {
		content: "";
		width: 26px;
		height: 2px;
		background: #757575;
		position: absolute;
		left: -2px;
		top: 10px;
		transform: rotate(45deg);
	}
	&::after {
		content: "";
		width: 26px;
		height: 2px;
		background: #757575;
		position: absolute;
		left: -2px;
		top: 10px;
		transform: rotate(-45deg);
	}
}
.cart-total {
	text-align: right;
	margin-top: 40px;
	.cart-item__prod-total {
		float: none;
		display: inline-block;
		margin: 0 109px 0 20px;
	}
	&--left {
		text-align: left;
	}
}
.cart-total__label {
	display: inline-block;
	font-size: 20px;
}

.to-order {
	display: inline-block;
	text-align: center;
	line-height: 48px;
	float: right;
	margin-top: 40px;
	margin-right: 60px;
}

.to-forgot-pass {
	float: right;
	background: none;
	border: 0;
	padding: 0;
	color: #004c6b;
}
.custom-checkbox {
	overflow: hidden;
	position: relative;
	label {
		color: #3c3b3a;
		&:before {
			content: "";
			display: inline-block;
			margin-right: 13px;
			width: 20px;
			height: 20px;
			border: 1px solid #e3e3e3;
			vertical-align: -4px;
		}
	}
	input {
		position: absolute;
		visibility: hidden;
		&:checked ~ label {
			color: #edb14e;
			&:before {
				border: 1px solid #edb14e;
				background: #edb14e url(../img/check.png) center no-repeat;
			}
		}
	}
	&--left {
		float: left;
	}
}

.custom-radio {
	overflow: hidden;
	position: relative;
	margin-bottom: 15px;
	&--bold {
		label {
			font-weight: bold;
		}
	}
	label {
		color: #3c3b3a;
		&:before {
			content: "";
			display: inline-block;
			margin-right: 13px;
			width: 22px;
			height: 22px;
			border: 1px solid #e3e3e3;
			vertical-align: -4px;
			border-radius: 50%;
		}
	}
	input {
		position: absolute;
		visibility: hidden;
		&:checked ~ label {
			color: #edb14e;
			&:before {
				border: 1px solid #edb14e;
				background: #edb14e url(../img/radio-check.png) center no-repeat;
			}
		}
	}
	&--left {
		float: left;
	}
}
.custom-radio__note {
	margin-left: 35px;
	line-height: normal;
	margin-top: 5px;
}

.pp-message {
	text-align: center;
	&__title {
		margin-bottom: 0;
	}
	&__text {
		padding: 30px;
	}
}

.auth-user {
	float: right;
	position: relative;
	width: 105px;
	text-align: center;
	margin-top: 15px;
	margin-right: 10px;
	&:hover &__dropdown {
		display: block;
	}
}
.auth-user__icon {
	width: 18px;
	height: 20px;
	fill: #6f9aac;
	vertical-align: -4px;
	margin-right: 4px;
	position: relative;
	z-index: 5;
}
.auth-user__name {
	color: #004c6b;
	font-size: 14px;
	position: relative;
	z-index: 5;
}
.auth-user__dropdown {
	display: none;
	width: 120%;
	position: absolute;
	z-index: 4;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
	background: #ffffff;
	left: -10%;
	top: -50%;
	padding-top: 55px;
	hr {
		margin: 0;
	}
}
.auth-user__link {
	display: block;
	height: 44px;
	line-height: 44px;
}

.mini-cart {
	width: 334px;
	background-color: #e5edf0;
	float: right;
	padding: 0 25px;
	margin-bottom: 20px;
}
.mini-cart__item {
	padding: 24px 0;
	border-bottom: 1px solid #d0d8db;
	overflow: hidden;
}
.mini-cart__item-img {
	float: left;
	margin-right: 20px;
	img {
		width: 88px;
		height: auto;
	}
}
.mini-cart__item__info {
	overflow: hidden;
}
.mini-cart__item-name {
	font-size: 14px;
	line-height: 18px;
	display: inline-block;
	margin-bottom: 10px;
}
.mini-cart__item-count {
	float: left;
}
.mini-cart__item-total {
	font-weight: bold;
	float: right;
}
.mini-cart__total {
	float: right;
	padding: 15px 0;
	span {
		font-weight: bold;
	}
}
.order-hidden {
	display: none;
}

.order-section {
	padding-bottom: 45px;
	border-bottom: 1px solid #e5edf0;
	margin-bottom: 30px;
}
.order-section__title {
	font-size: 23px;
	font-weight: 700;
	margin-bottom: 20px;
}

.delivery-type {
	float: left;
	width: 33.333%;
	border: 1px solid #dce4e7;
	border-left: 0;
	padding: 20px;
	&:first-child {
		border-left: 1px solid #dce4e7;
	}
}
.delivery-select {
	overflow: hidden;
}
.delivery-requisites {
	margin-top: 25px;
	margin-left: 35px;
}
.delivery-requisites__title {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 10px;
}
.order-form {
	margin-bottom: 50px;
	.form-subm {
		text-align: left;
	}
}
.book-preview__is-new {
	color: #edb14e;
}
.book-preview__is-new--infull {
	margin-top: 20px;
	font-size: 18px;
	margin-left: 5px;
}

.mobile-menu-toggle {
	appearance: none;
	width: 25px;
  height: 19px;	
  padding: 0;
  position: relative;
  margin: 17px auto 0;
  display: block;
  border: 0;
  background: none;
  &:before {
  	content: "";
  	position: absolute;
  	left: 0;
  	top: 0;
  	height: 3px;
  	width: 100%;
  	background: #454444;
  	box-shadow: 0 8px 0 0px #454444;
  }
  &:after {
  	content: "";
  	position: absolute;
  	left: 0;
  	bottom: 0;
  	height: 3px;
  	width: 100%;
  	background: #454444;
  }
  &--open {
  	&:before {
  		box-shadow: none;
  		transform: rotate(45deg);
  		top: 8px;
  	}
  	&:after {
  		transform: rotate(-45deg);
  		bottom: 8px;
  	}
  }
}

.mb-slidebar {
	display: none;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	position: fixed;
	background: #e5edf0;
	z-index: 100;
	padding-top: 20px;
	.search {
		margin-top: 20px;
		margin-right: 70px;
	}
	.mobile-menu-toggle--open {
		position: absolute;
		top: 23px;
		right: 20px;
	}
}

.mobile-menu {
	list-style: none;
	margin: 35px 0 0 5px;
	padding: 0;
}
.mobile-menu__item {
	margin-bottom: 25px;
}
.mobile-menu__link {
	color: #2d2d2d;
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
	display: inline-block;
	padding-bottom: 4px;
	&--active {
		color: #004c6b;
		border-bottom: 3px solid #edb14e;
	}
}

.categories-select {
	width: 100%;
	height: 40px;
	background: #e5edf0 url(../img/arrow-down-black.svg) 95% 50% no-repeat;
	background-size: 16px 9px;
	appearance: none;
	border: 0;
	border-radius: 0;
	padding: 0 15px;
	margin-bottom: 25px;
}

@import "responcive/1024.scss";
@import "responcive/991.scss";
@import "responcive/768.scss";
@import "responcive/767.scss";


