@media (max-width: 1024px) {
	.main-menu {
		display: block;
		margin-top: 20px;
	}
	.main-menu__item {
		display: block;
		margin-bottom: 10px;
	}
	.main-menu__link {
		display: inline-block;
		line-height: normal;
		height: auto;
	}
	.dropdown-menu {
		position: static;

	}
	body {
		padding-top: 78px;
	}
	.top-line{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 99;
	}
	.header {
		position: fixed;
		left: 0;
		top: 8px;
		width: 100%;
		padding-top: 7px;
		padding-bottom: 15px;
		z-index: 99;
	}
	.subscribe-banner__text {
		padding: 50px 0 0 40px;
	}
	.subscribe-banner:after {
    right: 8px;
	}
	body {
		margin-bottom: 0;
	}
	.book-preview__photo {
		width: 100%;
		img {
			width: 100%;
			height: auto;
		}
	}
	.mobile-menu-toggle {
		margin-top: 27px;
	}
	.header {
		.auth-user {
			margin-top: 28px;
		}
		.shopcart-block, .login-bt {
			margin-top: 28px;
		}
		.shopcart-block {
			margin-right: 20px;
		}
		.phone--header {
			margin-top: 12px;
		}
	}
	.logo {
		background: url(../img/mail-logo.jpg) no-repeat!important;
		background-size: cover!important;
    width: 266px;
    height: 69px;
	}
	.cart-add-message {
		top: 90px;
	}
	.main-slider {
		display: none;
	}
	.slick-nav-button--outer.slick-prev {
		left: 0;
	}
	.slick-nav-button--outer.slick-next {
		right: 0;
	}
	h1 {
		font-size: 40px;
		line-height: 50px;
		&.article-title {
			font-size: 38px;
			line-height: 40px;
		}
	}
	h2 {
		font-size: 36px;
		line-height: 45px;
	}
	h3 {
		font-size: 18px;
		line-height: 22px;
	}
	h4 {
		font-size: 16px;
		line-height: 20px;
	}
	.subscribe-banner {
		height: auto;
	}
	.subscribe-banner__text {
		padding: 20px;
	}
	.partner-item {
		text-align: center;
	}
	.p404__text-large {
		font-size: 164px;
		line-height: 200px;
	}
	.p404__text {
		padding-bottom: 60px;
	}
	.logo--404 {
    margin: 35px auto 0px;
	}
	.advantages__item--inside {
    padding: 20px;
	}
	.article-themes__link {
		padding: 0 10px;
	}
	.cart-item__prod-photo {
		float: none;
		margin: 0 auto 20px;
	}
	.cart-item__prod-info {
		float: none;
		width: auto;
		margin: 0;
	}
	.cart-item {
		position: relative;
	}
	.cart-item__remove {
		position: absolute;
    top: -50px;
    right: 20px;
	}
	.cart-item__prod-price {
		float: none;
		width: 100%;
		text-align: center;
		margin-top: 20px;
	}
	.cart-item__prod-count {
		float: none;
		margin: 20px auto 20px;
	}
	.cart-item__prod-total {
		float: none;
		width: 100%;
		text-align: center;
	}
	.cart-total {
		text-align: center;
	}
	.cart-total__label {
		margin-bottom: 30px;
		font-size: 28px;
	}
	.to-order {
		float: none;
		display: block;
		margin: 40px auto 0;
	}
	.cart-total .cart-item__prod-total {
		margin-left: 0;
	}
	.book-preview__photo {
		width: 212px;
	}
	.international-contacts .contact-item {
    float: left;
    width: 33.333%;
	}
	.book-cover {
		margin: 0 auto 30px;
		display: block;
	}
	.mini-cart {
		float: none;
		margin-bottom: 30px;
		width: auto;
	}
	.cart-total .cart-item__prod-total {
		margin: 0 auto;
	}
	.order-form .form-subm {
		text-align: center;
	}
	.footer {
		height: auto;
		padding: 20px 0;
		margin-top: 40px;
		position: static;
	}
}